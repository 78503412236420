<template>
 
  <v-app style="background-color:#f8f9fb" >

  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a {{ 
          this.dias }} dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
      v-model="dialog_acompanhamento"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      
      <v-card>
        <v-toolbar
          dark
          color="rgb(71, 130, 180)"
        >
          <v-btn
            icon
            dark
            @click="dialog_acompanhamento = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Acompanhamento de Indicadores - {{ this.indicador_selecionado.titulo }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
         
        </v-toolbar>

        <v-row style="margin-top: 1rem;margin-left: 1rem;">
          
          <v-col cols="6">
            <span style="font-weight: bold;">Resultado:</span> 
              {{ parseFloat(
                    this.indicador_selecionado.operacao === "Despesa" && this.indicador_selecionado.total > 0 
                      ? -this.indicador_selecionado.total 
                      : this.indicador_selecionado.total
                  ).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
              }}
            <span style="font-weight: bold;">Meta:</span> 
              {{ parseFloat(this.indicador_selecionado.meta).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }) 
              }}
          </v-col>
        </v-row>

        <v-row>
            <v-col cols="8" style="height: 400px;"   v-if="isLoadinggrafico" > 
              <v-progress-circular 
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col cols="8" style="height: 400px;" v-show="!isLoadinggrafico"  > 
              <apexcharts             
                type="line" 
                height="350" 
                :options="chartOptions" 
                :series="series">
              </apexcharts>
            </v-col>

            <v-col cols="4" style="height: 400px;">
                <apexcharts
                  type="bar"
                  height="250"
                  :options="percentualChartOptions"
                  :series="percentualSeries"
                ></apexcharts>
              </v-col>

        </v-row>
       

      </v-card>
    </v-dialog>

   
    <!-- Diálogo de progresso -->
    <v-dialog v-model="showProgress" persistent max-width="300">
    <v-card>
      <v-card-title>
        Baixando arquivo...
      </v-card-title>
      <v-card-text>
        <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>


  <v-container >
    <v-col cols="12" >
      <filtro-component :opcoes="opcoes" :modos="modos" :tipos="tipos" 
      @filtrar="filterData" :title_opcao="'Situação'"
        ></filtro-component>
    </v-col>
  </v-container>


  <v-container style="background-color:#eee"  
    id="contrato"
    fluid
    tag="section">
 
    <v-row>
    
    <v-col
    cols="12">

        <base-material-card
        icon="mdi-finance"
        title="Indicadores"
        color="red" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0">

          <br/>
          <v-expansion-panels  v-model="expandedPanels" expand multiple>
            <!-- Painel: Processos & Tecnologias -->
            
            <!-- Painel: Econômico & Financeiro -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                Econômico & Financeiro
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>
                  <v-data-table
                      :headers="documentos_header"
                      :items="documentos_economico_financeiro"
                      :items-per-page="500"
                      items-per-page-text="Linhas por página"
                      class="elevation-0"
                      item-key="id"
                      hide-default-footer
                    >
                            <template v-slot:top>
                              <v-progress-linear
                                v-if="isLoadingeconomico" 
                                indeterminate
                                color="rgb(71 130 180)"
                              ></v-progress-linear>
                            </template>

                      <template v-slot:item="props">
                        <tr :class="{'gray-row': props.item.index % 2 !== 0}" style="background-color: #f5f5f5;">
                          <!-- Exibição dos dados principais -->
                          <td style="width: 13%;font-size:14px;">
                            {{ props.item.classificacao }}
                          </td>
                          <td style="width: 50%;font-size:14px;">
                            {{ props.item.titulo }}
                          </td>
                          <td style="width: 20%;font-size:14px;">
                              {{
                                parseFloat(
                                  props.item.operacao === "Despesa" && props.item.total > 0 
                                    ? -props.item.total 
                                    : props.item.total
                                ).toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL'
                                })
                              }}
                              <!-- Ícones com tooltips -->
                              <template v-if="avaliarMeta(props.item.total, props.item.meta, props.item.operacao) === 'Meta superada'">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                      color="blue" 
                                      size="24" 
                                      class="ml-2 mr-1"
                                      v-bind="attrs"
                                      v-on="on">
                                      mdi-rhombus
                                    </v-icon>
                                  </template>
                                  <span>Meta superada</span>
                                </v-tooltip>
                              </template>

                              <template v-else-if="avaliarMeta(props.item.total, props.item.meta, props.item.operacao) === 'Meta atingida'">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                      color="green" 
                                      size="24" 
                                      class="ml-2 mr-1"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                    mdi-triangle
                                    </v-icon>
                                  </template>
                                  <span>Meta atingida</span>
                                </v-tooltip>
                              </template>

                              <template v-else-if="avaliarMeta(props.item.total, props.item.meta, props.item.operacao) === 'Meta quase alcançada'">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                      color="yellow" 
                                      size="24" 
                                      class="ml-2 mr-1"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      mdi-checkbox-blank
                                    </v-icon>
                                  </template>
                                  <span>Meta quase alcançada</span>
                                </v-tooltip>
                              </template>

                              <template v-else-if="avaliarMeta(props.item.total, props.item.meta, props.item.operacao) === 'Resultado insatisfatório'">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                      color="red" 
                                      size="24" 
                                      class="ml-2 mr-1"
                                      v-bind="attrs" style="transform: rotate(180deg);"
                                      v-on="on"
                                    >
                                      mdi-triangle

                                    </v-icon>
                                  </template>
                                  <span>Resultado insatisfatório</span>
                                </v-tooltip>
                              </template>
                            </td>

                          
                          <td style="width: 12%;font-size:14px;">
                            {{ parseFloat(props.item.meta).toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }) }}
                          </td>

                          <td style="width: 12%;font-size:14px;">
                            {{ calcularPercentual(props.item.total, props.item.meta, props.item.operacao) }}
                            
                          </td>
                          <!-- Botão de expandir -->
                          <td style="width: 18%;font-size:14px; display: flex; align-items: center;">
                            <v-btn 
                              style="margin-right: 8px;" 
                              dark
                              small
                              icon
                              @click="toggleExpand(props.item)"
                            >
                              <v-icon style="background-color: transparent;font-size: 34px;color: gray">{{ props.item.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>

                            <v-btn  
                              dark
                              small
                              color="gray" 
                              icon
                              @click="acompanhamento_ef(props.item)"
                            >
                              <v-icon style="background-color: transparent;font-size: 22px;color: gray">mdi-chart-areaspline</v-icon>
                            </v-btn>
                          </td>
                        </tr>

                        <!-- A linha expandida só será visível se "expanded" for true -->
                        <tr v-if="props.item.expanded">
                          <td colspan="8">
                            <v-simple-table >
                              <thead>
                                <tr>
                                  <th>Classificação</th>
                                  <th>Indicador</th>
                                  <th>Real</th>
                                  <th>Meta</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, index) in props.item.lista_volume" :key="index" >
                                  <td style="font-size: 12px !important;">{{ item.classificacao }}</td>
                                  <td style="font-size: 12px !important;">{{ item.titulo }}</td>
                                  <td style="font-size: 12px !important;">
                                    {{ item.tipo === 'R$' 
                                      ? parseFloat(item.valor).toLocaleString('pt-BR', {
                                          style: 'currency',
                                          currency: 'BRL'
                                        })
                                      : item.valor }}
                                  </td>
                                  <td style="font-size: 12px !important;">
                                    {{ item.tipo === 'R$' 
                                      ? parseFloat(item.meta).toLocaleString('pt-BR', {
                                          style: 'currency',
                                          currency: 'BRL'
                                        })
                                      : item.meta }}
                                  </td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel value="true">
              <v-expansion-panel-header>
                Processos & Tecnologias
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>
                  <v-data-table
                      :headers="documentos_header"
                      :items="documentos_processo_tecnologia"
                      :items-per-page="500"
                      items-per-page-text="Linhas por página"
                      class="elevation-0"
                      item-key="id"
                      hide-default-footer

                    >

                            <template v-slot:top>
                              <v-progress-linear
                                v-if="isLoadingprocessos" 
                                indeterminate
                                color="rgb(71 130 180)"
                              ></v-progress-linear>
                            </template>


                      <template v-slot:item="props">
                        <tr :class="{'gray-row': props.item.index % 2 !== 0}" style="background-color: #f5f5f5;">
                          <!-- Exibição dos dados principais -->
                          <td style="width: 13%;font-size:14px;">
                            {{ props.item.classificacao }}
                          </td>
                          <td style="width: 40%;font-size:14px;">
                            {{ props.item.titulo }}
                          </td>
                          <td style="width: 20%;font-size:14px;">
                              {{
                                parseFloat(
                                  props.item.operacao === "Despesa" && props.item.total > 0 
                                    ? -props.item.total 
                                    : props.item.total
                                ).toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL'
                                })
                              }}

                              <!-- Ícones com tooltips -->
                              <template v-if="avaliarMeta(props.item.total, props.item.meta, props.item.operacao) === 'Meta superada'">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                      color="blue" 
                                      size="24" 
                                      class="ml-2 mr-1"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      mdi-rhombus
                                    </v-icon>
                                  </template>
                                  <span>Meta superada</span>
                                </v-tooltip>
                              </template>

                              <template v-else-if="avaliarMeta(props.item.total, props.item.meta, props.item.operacao) === 'Meta atingida'">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                      color="green" 
                                      size="24" 
                                      class="ml-2 mr-1"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                    mdi-triangle
                                    </v-icon>
                                  </template>
                                  <span>Meta atingida</span>
                                </v-tooltip>
                              </template>

                              <template v-else-if="avaliarMeta(props.item.total, props.item.meta, props.item.operacao) === 'Meta quase alcançada'">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                      color="yellow" 
                                      size="24" 
                                      class="ml-2 mr-1"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      mdi-checkbox-blank
                                    </v-icon>
                                  </template>
                                  <span>Meta quase alcançada</span>
                                </v-tooltip>
                              </template>

                              <template v-else-if="avaliarMeta(props.item.total, props.item.meta, props.item.operacao) === 'Resultado insatisfatório'">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                      color="red" 
                                      size="24" 
                                      class="ml-2 mr-1"
                                      v-bind="attrs" style="transform: rotate(180deg);"
                                      v-on="on"
                                    >
                                      mdi-triangle
                                    </v-icon>
                                  </template>
                                  <span>Resultado insatisfatório</span>
                                </v-tooltip>
                              </template>
                            </td>

                          
                          <td style="width: 12%;font-size:14px;">
                            {{ parseFloat(props.item.meta).toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }) }}
                          </td>

                          <td style="width: 12%;font-size:14px;">
                            {{ calcularPercentual(props.item.total, props.item.meta, props.item.operacao) }}
                            
                          </td>
                          <!-- Botão de expandir -->
                          <td style="width: 18%;font-size:14px; display: flex; align-items: center;">
                            <v-btn 
                              style="margin-right: 8px;" 
                              dark
                              small
                              icon
                              @click="toggleExpand(props.item)"
                            >
                              <v-icon style="background-color: transparent;font-size: 34px;color: gray">{{ props.item.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>

                            <v-btn  
                              dark
                              small
                              color="gray" 
                              icon
                              @click="acompanhamento_pt(props.item)"
                            >
                              <v-icon style="background-color: transparent;font-size: 22px;color: gray">mdi-chart-areaspline</v-icon>
                            </v-btn>
                          </td>
                        </tr>

                        <!-- A linha expandida só será visível se "expanded" for true -->
                        <tr v-if="props.item.expanded">
                          <td colspan="8">
                            <v-simple-table >
                              <thead>
                                <tr>
                                  <th>Chave</th>
                                  <th>Data</th>
                                  <th>Documento</th>
                                  <th>Valor</th>
                                  <th>Histórico Complementos</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, index) in props.item.lista" :key="index" >
                                  <td style="font-size: 12px !important;">{{ item.chave }}</td>
                                  <td style="font-size: 12px !important;">{{ formatDate(item.data) }}</td>
                                  <td style="font-size: 12px !important;">{{ item.documento }}</td>
                                  <td style="font-size: 12px !important;">
                                    {{ parseFloat(item.valor).toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL'
                                    }) }}
                                  </td>
                                  <td style="font-size: 12px !important;">{{ item.historicoComplementos }}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <br/>
         </base-material-card>
          <br/>

    </v-col>
  </v-row>


  <div class="d-flex justify-end" >
          <div style="width: 240px;">
          
            <span @click="download_xls" title="Enviar Dados ao Stratws"  style="color:purple; font-size: 48px; margin-right: 24px; cursor: pointer;">
              <i class="fas fa-paper-plane"></i> <!-- Ícone de envio (avião de papel) -->
            </span>

            <span @click="download_xls" title="Fazer Download do Arquivo" style="color:green; font-size: 48px; margin-right: 12px; cursor: pointer;">
              <i class="fas fa-file-excel"></i> 
            </span>
            
            <br/>
          
          </div>
        </div>  

  </v-container>

</v-app> 
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Indicador from '../../services/indicadores'
import { ContentLoader } from 'vue-content-loader'
import FiltroComponent from "./components/FiltroStratus.vue"; // Certifique-se de que o caminho esteja correto


  export default {
    name: 'Cobranca',

    components: {
      apexcharts: VueApexCharts,
      FiltroComponent,
      ContentLoader,
    },

    mounted() {
    },

    computed: {
     // isChartReady() {
      //  return this.chartOptions && this.isLoadinggrafico === false;
      //},

      chartOptions() {
          return {
            chart: {
              type: 'line', // Tipo de gráfico (linha)
              height: 350,
              toolbar: {
                show: true, // Exibe a barra de ferramentas (zoom, exportação, etc.)
              }
            },
            xaxis: {
              categories: this.lista_meses, // Meses no eixo X
              title: {
                text: 'Meses', // Título do eixo X
              }
            },
            yaxis: {
              title: {
                text: 'Valores (R$)', // Título do eixo Y
              },
              labels: {
                formatter: (value) => {
                  return `R$ ${value.toLocaleString('pt-BR')}`; // Formatação de valores no eixo Y
                }
              }
            },
            tooltip: {
              y: {
                formatter: (value) => {
                  return `R$ ${value.toLocaleString('pt-BR')}`; // Formatação do valor no tooltip
                }
              }
            },
            stroke: {
              curve: 'smooth' // Suaviza a linha
            },
            markers: {
              size: 5, // Tamanho dos marcadores
            },
            colors: ['#0088CC', '#FF0000'], // Cores para as duas linhas: azul (Real) e vermelho (Meta)
            series: [
              {
                name: 'Real', // Nome da série Real
                data: this.series.realData || [] // Dados da série Real
              },
              {
                name: 'Meta', // Nome da série Meta
                data: this.series.metaData || [] // Dados da série Meta
              }
            ]
          };
        },

      
    },
 
    data () {
      return {

        rules: {
            required: value => !!value || 'Obrigatório.',
          },

          expandedPanels: [0, 1], // Expande ambos os painéis: Processos (0) e Econômico (1)
          empresa: 'Todas',
          search: "",
          selectedCompany: "",
          dialog_acompanhamento: false,
          selectedType: "Todos",
          selectedOpcao: "Todas",
          selectMode: "Todos",
          produto: 'Todos',
          isLoadinggrafico: false,
          isLoadingprocessos: false,
          isLoadingeconomico: false,
          selectedMonth : '',
          selectedYear : '',
          opcoes: [],
          modos: [],
          showFilter: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ"],
          tipos: ["Todos", "PROCESSOS & TECNOLOGIAS", "ECONÔMICO & FINANCEIRO"],
          showProgress: false,
          currentGroup: null,
          isLoading : false,
          dias : 1,
          indicador_selecionado: {},
          tipos_funerarios: [
                "Todos",
              ],

          documentos_economico_financeiro: [],
          documentos_processo_tecnologia: [],
          documentos_header: [
              { text: 'Classificação', value: 'classificacao' },
              { text: 'Indicador', value: 'titulo' },
              { text: 'Real', value: 'total' },
              { text: 'Meta', value: 'meta' },
              { text: '%', value: '%' },
              { text: '', value: 'actions', sortable: false },

            ],

            series: {
              realData: [], // Inicializado vazio
              metaData: []  // Inicializado vazio
            },
            lista_meses: [],

            percentualSeries: [
                {
                  name: '0 a 90% - Resultado insatisfatório',
                  data: [90],  // Primeira parte da barra (0 a 90%) - vermelha
                },
                {
                  name: '91 a 95% - Meta quase alcançada',
                  data: [5],   // Segunda parte da barra (91 a 95%) - amarela
                },
                {
                  name: '96 a 105% - Meta atingida',
                  data: [10],  // Terceira parte da barra (96 a 105%) - verde
                },
                {
                  name: '>= 106% - Meta superada',
                  data: [10],  // Quarta parte da barra (> 106%) - azul
                }
              ],

              percentualChartOptions: {
                    chart: {
                      type: 'bar',
                      height: 350,
                      stacked: true,
                    },
                    plotOptions: {
                      bar: {
                        horizontal: true,
                        dataLabels: {
                          enabled: false,
                        },
                      },
                    },
                    stroke: {
                      width: 1,
                      colors: ['#fff']
                    },
                    title: {
                      text: '% de atingimento'
                    },
                    xaxis: {
                      categories: [],
                      labels: {
                        show: true,  // Exibe as marcas no eixo X
                      },
                      min: 0,  // Valor mínimo do eixo X
                      max: 120, // Valor máximo do eixo X
                      tickAmount: 6,  // Marca a régua com intervalos de 20 (0, 20, 40, 60, 80, 100, 120)
                    },
                    yaxis: {
                      title: {
                        text: undefined
                      },
                      labels: {
                        show: false,  // Desativa os rótulos ao longo da régua
                      },
                    },
                    tooltip: {
                      y: {
                        formatter: function (val) {
                          return ""; // Formata o tooltip para mostrar o valor em percentual
                        }
                      }
                    },
                    fill: {
                      opacity: 1
                    },
                    colors: [
                      '#ff0000', // Vermelho para Resultado Insatisfatório (0 a 90%)
                      '#ffcc00', // Amarelo para Meta quase alcançada (91 a 95%)
                      '#008000', // Verde para Meta atingida (96 a 105%)
                      '#0000ff', // Azul para Meta superada (> 106%)
                    ],
                    legend: {
                      position: 'top',
                      horizontalAlign: 'left',
                      offsetX: 20
                    }
                  }



          };
      
    },

    methods: {

      formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },


    filterData(valores) {
        this.isLoading = true;
        this.selectedType = valores.selectedType;
        this.selectedMonth = valores.selectedMonth;
        this.selectedYear = valores.selectedYear;
        this.selectedCompany = valores.selectedCompany.join(",");       
        this.dashboard();
    },

    toggleExpand(item) {
      item.expanded = !item.expanded;  // Alterna o estado de expandido
    },
    getEmpresa (status) {
            switch (status) {
            case 'PARQUE DAS FLORES': return 'orange darken-2'
            case 'CEMITERIO PREVIDA' : return 'blue lighten-1'
            case 'MEMORIAL PARQUE MACEIÓ' : return 'green lighten-1'
            case 'PARQUE DO AGRESTE' : return 'cyan lighten-1'
            case 'PREVIDA' : return 'blue lighten-1'
            case 'A FLOR DO CAFÉ' : return 'purple lighten-1'

              default: 'success'
            }
      },

      acompanhamento_pt(selecionado){
        this.indicador_selecionado = selecionado
        this.dialog_acompanhamento = true;
        this.isLoadinggrafico = true;
        if(this.selectedCompany === '') return
 
              const meses = {
                  "Janeiro": 0,
                  "Fevereiro": 1,
                  "Março": 2,
                  "Abril": 3,
                  "Maio": 4,
                  "Junho": 5,
                  "Julho": 6,
                  "Agosto": 7,
                  "Setembro": 8,
                  "Outubro": 9,
                  "Novembro": 10,
                  "Dezembro": 11
              };

              // Converte o nome do mês selecionado para o número
              let mes = meses[this.selectedMonth];
              let ano = parseInt(this.selectedYear, 10); // Garante que seja um número

              let startDate = new Date(ano, mes, 1);
              let endDate = new Date(ano, mes + 1, 0);

              // Formata as datas
              let formattedStartDate = formatDateConveter(startDate);
              let formattedEndDate = formatDateConveter(endDate);


        Indicador.grafico_indicador_pt(formattedStartDate, formattedEndDate, this.selectedCompany, selecionado.titulo).then(response => {
                          // Preencher os dados para o gráfico
                              const dados = response.data;
                              this.series.realData = dados.map(item => item.real); // Dados reais
                              this.series.metaData = dados.map(item => item.meta); // Dados das metas
                              this.lista_meses = dados.map(item => item.mes); // Extrai os meses do JSON

                          }).catch(e => {    
                          }).finally(() => {
                            this.isLoadinggrafico = false;
                          });

      },


      acompanhamento_ef(selecionado){
        this.indicador_selecionado = selecionado
        this.dialog_acompanhamento = true;
        this.isLoadinggrafico = true;
        if(this.selectedCompany === '') return
 
              const meses = {
                  "Janeiro": 0,
                  "Fevereiro": 1,
                  "Março": 2,
                  "Abril": 3,
                  "Maio": 4,
                  "Junho": 5,
                  "Julho": 6,
                  "Agosto": 7,
                  "Setembro": 8,
                  "Outubro": 9,
                  "Novembro": 10,
                  "Dezembro": 11
              };

              // Converte o nome do mês selecionado para o número
              let mes = meses[this.selectedMonth];
              let ano = parseInt(this.selectedYear, 10); // Garante que seja um número

              let startDate = new Date(ano, mes, 1);
              let endDate = new Date(ano, mes + 1, 0);

              // Formata as datas
              let formattedStartDate = formatDateConveter(startDate);
              let formattedEndDate = formatDateConveter(endDate);


        Indicador.grafico_indicador_ef(formattedStartDate, formattedEndDate, this.selectedCompany, selecionado.titulo).then(response => {
                          // Preencher os dados para o gráfico
                              const dados = response.data;
                              this.series.realData = dados.map(item => item.real); // Dados reais
                              this.series.metaData = dados.map(item => item.meta); // Dados das metas
                              this.lista_meses = dados.map(item => item.mes); // Extrai os meses do JSON

                          }).catch(e => {    
                          }).finally(() => {
                            this.isLoadinggrafico = false;
                          });

      },

      calcularPercentual(valor, meta, operacao) {
          if (operacao === "Despesa") {
            valor = -valor;
          }
          if (meta === 0) {
            return "";
          }
          const percentualAtingido = (Math.abs(valor) / Math.abs(meta)) * 100;
          return parseFloat(percentualAtingido.toFixed(2));
      },
        
      avaliarMeta(valor, meta, operacao) {
          // Converte o valor para negativo se a operação for "Despesa"
          if (operacao === "Despesa") {
            valor = -valor;
          }

          if (meta === 0) {
            return "";
          }
          // Calcula o percentual atingido usando valores absolutos
          const percentualAtingido = (Math.abs(valor) / Math.abs(meta)) * 100;

          // Determina a mensagem com base no percentual
          if (operacao === "Despesa") {
            // Lógica invertida para Despesa
            if (percentualAtingido >= 0 && percentualAtingido <= 90) {
              return "Meta superada"; // Gastou menos do que o previsto (bom)
            } else if (percentualAtingido > 90 && percentualAtingido <= 95) {
              return "Meta atingida"; // Quase gastou o previsto
            } else if (percentualAtingido > 95 && percentualAtingido <= 105) {
              return "Meta quase alcançada"; // Gastou próximo do previsto
            } else if (percentualAtingido > 105) {
              return "Resultado insatisfatório"; // Gastou mais do que o previsto (ruim)
            } else {
              return "";
            }
          } else {
            // Lógica normal para Receita
            if (percentualAtingido >= 0 && percentualAtingido <= 90) {
              return "Resultado insatisfatório";
            } else if (percentualAtingido > 90 && percentualAtingido <= 95) {
              return "Meta quase alcançada";
            } else if (percentualAtingido > 95 && percentualAtingido <= 105) {
              return "Meta atingida";
            } else if (percentualAtingido > 105) {
              return "Meta superada";
            } else {
              return "";
            }
          }
        },

 
      getFirstDayOfMonth() {
        const dataAtual = new Date();
        const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        return this.formatDateBR(primeiroDiaDoMes);
        },



    getStatus (status) {
            switch (status) {
            case 'Ativo': return 'blue lighten-1'
            case 'Cancelado' : return 'red lighten-1'
             default: 'success'
            }
      },


      formatDate(dateString) {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },


      download_xls(){
        alert('Em Manutenção')
      },


      dashboard() {

              if(this.selectedCompany === '') return
 
              const meses = {
                  "Janeiro": 0,
                  "Fevereiro": 1,
                  "Março": 2,
                  "Abril": 3,
                  "Maio": 4,
                  "Junho": 5,
                  "Julho": 6,
                  "Agosto": 7,
                  "Setembro": 8,
                  "Outubro": 9,
                  "Novembro": 10,
                  "Dezembro": 11
              };

              // Converte o nome do mês selecionado para o número
              let mes = meses[this.selectedMonth];
              let ano = parseInt(this.selectedYear, 10); // Garante que seja um número

              let startDate = new Date(ano, mes, 1);
              let endDate = new Date(ano, mes + 1, 0);

              // Formata as datas
              let formattedStartDate = formatDateConveter(startDate);
              let formattedEndDate = formatDateConveter(endDate);

             
             
              if(this.selectedType === 'PROCESSOS & TECNOLOGIAS' || this.selectedType === 'Todos'){

                this.isLoadingprocessos= true;
                this.documentos_processo_tecnologia = [];

                Indicador.documentos_processo_tecnologia(formattedStartDate, formattedEndDate, this.selectedCompany).then(response => {
                            if(response.status === 200){
                              this.documentos_processo_tecnologia = response.data;
                            } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.isLoadingprocessos = false;
                          });
              }

              if(this.selectedType === 'ECONÔMICO & FINANCEIRO' || this.selectedType === 'Todos'){
               
                this.isLoadingeconomico = true;
                this.documentos_economico_financeiro = [];

                Indicador.documentos_economico_financeiro(formattedStartDate, formattedEndDate, this.selectedCompany).then(response => {
                          if(response.status === 200){
                            this.documentos_economico_financeiro = response.data;
                          } 

                        }).catch(e => {    
                        }).finally(() => {
                          this.isLoadingeconomico = false;
                         });
              }
                         

      },

      calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },
    },
  }

  function formatDateConveter(date) {
          const year = date.getFullYear(); // Obtém o ano
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Obtém o mês (adiciona 1 e garante dois dígitos)
          const day = String(date.getDate()).padStart(2, '0'); // Obtém o dia (garante dois dígitos)
          return `${year}-${month}-${day}`;
  }

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }


</script>

<style scoped>

  .v-progress-circular {
    margin: 1rem;
  }

  .custom-gray-background {
    height: 80px;
  }

  
  .vuetify__expand-icon {
  display: none !important; /* Oculta os ícones de expandir */
}
  /* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
  .fill-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
  }
  
  /* Centralize horizontalmente */
  .justify-center {
    justify-content: center;
  }

  .card-loader {
    height: 100%; /* Define a altura do content-loader como 100% para preencher o v-card */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .v-data-table-header-mobile .v-data-table-header-group {
  display: none !important; /* Oculta os botões de expansão de grupo */
  }
  
  
  </style>
