import { http } from './config'
import authHeader from './auth-header';

export default {


    documentos_processo_tecnologia: (dataI, dataF, nomeEmpresa) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa
        }
        return http.get('/stratus/indicadores_processo_tecnologia', {
           params: params,
           headers: authHeader()   
        })
    },

    documentos_economico_financeiro: (dataI, dataF, nomeEmpresa) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa
        }
        return http.get('/stratus/documentos_economico_financeiro', {
           params: params,
           headers: authHeader()   
        })
    },

    grafico_indicador_pt: (dataI, dataF, nomeEmpresa, titulo) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'titulo': titulo
        }
        return http.get('/stratus/grafico_indicador_pt', {
           params: params,
           headers: authHeader()   
        })
    },

    grafico_indicador_ef: (dataI, dataF, nomeEmpresa, titulo) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'titulo': titulo
        }
        return http.get('/stratus/grafico_indicador_ef', {
           params: params,
           headers: authHeader()   
        })
    },
    
}
